html body {
  .cx-component-library {
    .ReactTable{
      &.no-header{
        .-headerGroups{
          display: none;
        }
      }
      &.no-border{
        border: none;
        .rt-tbody{
          .rt-tr-group{
            border-bottom: none;
          }
        }
        .-pagination{
          border: none;
          box-shadow: none;
        }
      }

      a {
        color: $Atlantis;
        font-weight: bold;

        &:hover{
          color: $Water;
        }
      }

      .rt-thead.-headerGroups{
        .rt-tr{
          .rt-th{
            padding: 20px;
            color: $Salt;
            font-size: 18px;
            background-color: #2b082c;
            font-weight: bold;
            text-align: left;
          }
        }
      }

      .rt-tr.-odd{
        background: none;
      }

      .rt-tbody {
        .rt-td{
          padding: 15px 8px;
        }
      }

      .select-wrap{
        select{
          margin-bottom: 0;
        }
      }

      .-pageJump{
        input{
          margin-bottom: 0;
        }
      }

      .-pagination{
        .-center {
          flex: 9.5;
        }


        .-previous{
          margin-top: 3px;
          margin-left: 3px;
        }

        .-next{
          margin-top: 3px;
          margin-right: 3px;
        }

        .-next, .-previous{
          height: fit-content;
          background-color: $PinkAction;
          border-color: $PinkAction;

          button.-btn{
            color: $Salt;
          }

          &:hover{
            background-color: $DarkPinkAction;
            border-color: $DarkPinkAction;
          }
        }
      }

      .actions{
        a{
          margin-right: 15px;
          display: inline-block;
        }
      }
    }
  }

}

