%control {
  -webkit-appearance: none;
  background: $Salt;
  width: 100%;
  border: rem(1) solid $Ash;
  border-radius: $border-radius;
  display: block;
  font-size: rem($bodyfont);
  height: $inputHeight;
}

%clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

.clearfix {
  @extend %clearfix;
}

%noselect {
  -webkit-touch-callout: none;
  user-select: none;
  user-drag: none;
  -webkit-user-drag: none;
  -moz-user-drag: none;
  -ms-user-drag: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &:focus {
    outline: none;
  }
}

:focus {
  outline: none;
}

.noselect {
  @extend %noselect;
}

.abs {
  position: absolute;
  &--left { left: 0; }
  &--right { right: 0; }
}

.rel {
  position: relative;
}

.fixed {
  position: fixed;
}

.noselect-all > * {
  @extend %noselect;
}

.no-padding {
  padding: 0 !important;
}

.input-height {
  height: $inputHeight;
  line-height: $inputHeight;
}

.padded {
  &--top {
    padding-top: 2rem;
  }
  &--bottom {
    padding-top: 2rem;
  }
  padding: 2rem;
}

.no-margin {
  margin: 0 !important;
}

.left {
  float: left;
}

.flex {
  display: flex;
  &.align-center {
    align-items: center;
  }
}

.right {
  float: right;
}

.left-align{
  text-align: left !important;
}

.right-align{
  text-align: right !important;
}

.center-align{
  text-align: center !important;
}

.data-wait {
  opacity: 0.5;
}

.hidden {
  display: none !important;
}

.invisible {
  visibility: hidden !important;
}

.caps {
  text-transform: uppercase;
}

.light{
  font-family: $f-app-font-light;
}

.regular{
  font-family: $f-app-font-regular;
}

.semibold{
  font-family: $f-app-font-semibold;
}

.bold{
  font-family: $f-app-font-bold;
}

.inline-block {
  display: inline-block;
}

.inline-grid {
  display: inline-grid;
}

.rotate90{
  @include transform(rotate(90deg));
}

.rotate180{
  @include transform(rotate(180deg));
}

.rotate270{
  @include transform(rotate(270deg));
}

.ellipsis{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.shadow {
  @include shadow();
}

.click-interceptor {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
